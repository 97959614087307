'use client'

import { signIn, type SignInAuthorizationParams } from 'next-auth/react'
import { type PropsWithChildren } from 'react'

import styles from './styles.module.css'

interface Props {
  providerId: string
  variant: 'primary' | 'secondary' | 'link'
  authorizationParams?: SignInAuthorizationParams
}

const LoginButton = ({ providerId, children, variant, authorizationParams }: PropsWithChildren<Props>): JSX.Element => {
  const buttonVariant = `button-${variant}`

  return (
    <button
      className={`${styles[buttonVariant]}`}
      data-test={providerId}
      onClick={() => {
        void (async () => {
          await signIn(providerId, {}, authorizationParams)
        })()
      }}
    >
      {children}
    </button>
  )
}

export default LoginButton
