'use client'

import { useTranslations } from 'next-intl'

import FilledButton from '@/components/Button/FilledButton'
import { Link } from '@/lib/i18n/navigation'

const NewsButton = (): JSX.Element => {
  const t = useTranslations('news')

  return (
    <FilledButton asChild theme="light">
      <Link href="/news">{t('seeNewsAndUpdates')}</Link>
    </FilledButton>
  )
}

export default NewsButton
